import moment from 'moment';
import difference from 'lodash/difference';
import uniq from 'lodash/uniq';
import trim from 'lodash/trim';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import upperFirst from 'lodash/upperFirst';
import { CP_BASE_URL } from './globals';
import authHelper from 'auth/auth-helper';
import { DerivedTheme } from './Types';
import { Routes } from './constants';

const RTL_LANGS = ['ar'];
class Helper {
    static isLanguageRTL(lng: string) {
        return RTL_LANGS.indexOf(lng) !== -1;
    }

    static languageDirection(language: string): 'rtl' | 'ltr' {
        const rtl = Helper.isLanguageRTL(language);
        return rtl ? 'rtl' : 'ltr';
    }

    static stops(e: Event) {
        e.preventDefault();
        e.stopPropagation();
    }

    static isValidArray(inputArray: any[]) {
        if (!inputArray) return false;
        if (Array.isArray(inputArray) && inputArray.length) return true;
        return false;
    }

    static isValidArrayIndex(idx: any) {
        return !Number.isNaN(idx) && idx > -1;
    }

    static isValidString(inputString: string) {
        if (!inputString) return false;
        if (typeof inputString === 'string') return true;
        return false;
    }

    static isValidNumber(inputNumber: number) {
        if (!inputNumber && inputNumber !== 0) return false;
        if (!Number.isNaN(inputNumber)) return true;
        return false;
    }

    static isValidBoolean(inputBoolean: boolean) {
        return typeof inputBoolean === 'boolean';
    }

    static getFormattedDateTime(formatString: string, epcoh: moment.MomentInput = moment()) {
        return moment(epcoh).format(formatString);
    }

    static getFromNow(epcoh: moment.MomentInput): string {
        return moment(epcoh).fromNow();
    }

    static onlyUnique(value: any, index: number, self: any) {
        return self.indexOf(value) === index;
    }

    static validatePositiveInt(rule: any, value: any, cb: any) {
        if (value < 0) {
            return cb('Enter a positive number');
        }
        return cb();
    }

    static formatPriceAfterMillion(amount: number) {
        if (amount >= 1.0e9) {
            return `${Number(amount / 1.0e9).toFixed(2)}B`;
        }
        if (amount >= 1.0e6) {
            return `${Number((amount / 1.0e6).toFixed(2))}M`;
        }
        return amount;
    }

    static equal<T>(first: T, second: T): boolean {
        return isEqual(first, second);
    }

    static clone<T>(element: T): T {
        return cloneDeep(element);
    }

    static trimString(str: string): string {
        return trim(str);
    }

    static uniqArray<T>(array: T[]): T[] {
        return uniq(array);
    }

    static differenceArray<T>(first: T[], second: T[]): T[] {
        return difference(first, second);
    }

    static getDateValue(date: Date) {
        return date ? moment(date) : undefined;
    }

    static sleep(ms: number) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    static sortByDate(sortByOrder: any, list: any, key: string) {
        list.sort((a: any, b: any) => {
            if (a[key] === b[key]) {
                return 0;
            }
            if (a[key] === null) {
                return 1;
            }
            if (b[key] === null) {
                return -1;
            }
            if (sortByOrder === 'ASC') {
                return moment(a[key]).diff(b[key]);
            }
            return moment(b[key]).diff(a[key]);
        });
    }

    static checkForEquality(newVal: any, oldVal: any) {
        if (newVal instanceof Array) {
            return JSON.stringify(newVal) === JSON.stringify(oldVal);
        }
        return newVal === oldVal;
    }

    static validatePassword(pass: string) {
        const regex = /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/;
        return regex.test(pass);
    }

    static validateEmail = (email: string) => {
        if (!email) {
            return false;
        }

        return String(email).toLowerCase().trim().match(
            /\S+@\S+\.\S+/,
        );
    };

    static getLabelFromKey(key: string, arrayList: any[]) {
        return arrayList?.find((item) => item.key === key)?.label;
    }

    static unionSet(sets: Set<any>[]): Set<any> {
        const newSet = new Set();
        sets.forEach((set) => set.forEach((elem) => newSet.add(elem)));
        return newSet;
    }

    static snakeToPretty(str: string) {
        if (!str) {
            return str;
        }
        const pretty = str.replace(
            /([-_][a-z])/g,
            (group: string) => group.toUpperCase()
                .replace('-', ' ')
                .replace('_', ' '),
        );
        return upperFirst(pretty);
    }

    static downloadFileData(data: any, fileName: string, extensionIncluded = false, type?: string) {
        const link = document.createElement('a');
        let file;
        if (type === 'pdf') {
            file = new Blob([data], { type: 'application/pdf' });
            link.download = fileName;
            link.setAttribute('style', "display: 'none'");
        } else {
            file = new Blob([data]);
            link.setAttribute('download', `${fileName}${extensionIncluded ? '' : '.xlsx'}`);
        }
        const fileUrl = URL.createObjectURL(file);
        link.href = fileUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    static getFileExtension(filename: string) {
        const ext = /^.+\.([^.]+)$/.exec(filename);
        return ext == null ? '' : ext[1];
    }

    static getFileName(data: any) {
        const disposition = data && data.headers && data.headers['content-disposition'];
        let filename = '';
        if (disposition) {
            const matches = disposition.match(/filename="(.*)"/);
            filename = matches && matches.length && matches.length > 0 ? decodeURI(matches[1]) : '';
        }
        return filename;
    }

    static handleXLSXFile = async (
        file: Blob,
        headers: string[],
        range = 1,
        encoding: any = null,
    ): Promise<any> => {
        const XLSX = await import('xlsx');
        return new Promise((resolve) => {
            const reader = new FileReader();
            let fileValue;
            const codepage = encoding || 65001;
            reader.onload = () => {
                fileValue = reader.result;
                const wb = XLSX.read(fileValue, {
                    type: 'binary',
                    codepage,
                    raw: true,
                    cellDates: true,
                });
                /* sheet to json */
                /* array inside array without header */
                const wsNames = wb.SheetNames;
                const result: any = [];
                for (let i = 0; i < wsNames.length; i += 1) {
                    const sheet = XLSX.utils.sheet_to_json(
                        wb.Sheets[wsNames[i]],
                        {
                            header: headers,
                            range,
                        },
                    );
                    result.push(sheet);
                }
                resolve(result);
            };
            reader.readAsBinaryString(file);
        });
    };

    static converttoKilogram = (value: number | string, currentUnit: 'kg' | 'gm') => {
        if (currentUnit === 'kg') {
            return value;
        }
        return (Number(value) / 1000).toFixed(5);
    };

    static centemeterMultiplier = {
        cm: 1,
        inch: 2.54,
        ft: 30.48,
        mtr: 100,
    };

    static converttoCentimeter = (value: number | string, currentUnit: 'cm' | 'ft' | 'mtr' | 'inch') => {
        const multiplier = Helper.centemeterMultiplier[currentUnit];
        return Number(value) * multiplier;
    };

    static redirectToOldPortal = (baseUrl?: string) => {
        let redirectUrl = baseUrl;
        if (!baseUrl) {
            const orgId = authHelper.getOrganisationId();
            redirectUrl = CP_BASE_URL.replace('{organisationId}', orgId === '1' ? 'dtdc' : orgId);
        }
        // const storage = window.localStorage;
        // const redirect_obj = {
        //     organisationId: storage.getItem('organisationId'),
        //     authToken: storage.getItem('authToken'),
        //     userId: storage.getItem('userId'),
        //     username: storage.getItem('username'),
        //     userCode: storage.getItem('userCode'),
        //     logoUrl: storage.getItem('logoUrl'),
        // };

        // const encodedString = Buffer.from(JSON.stringify(redirect_obj)).toString('base64');
        // const old_cp_url = `${redirectUrl}/login?key=${encodedString}`;

        // Removing sending "key" to old portal, because currently old portal does not support reading it
        const old_cp_url = redirectUrl || '';
        window.location.href = old_cp_url;
    };

    static changeFavicon = (faviconUrl?: string) => {
        const storage = window.localStorage;
        const favIcon = faviconUrl || storage.getItem('faviconUrl');
        if (favIcon) {
            const favicon: any = document.getElementById('favicon');
            const appleIcon: any = document.getElementById('apple-touch-icon');
            if (favicon) {
                favicon.href = faviconUrl;
            }
            if (appleIcon) {
                appleIcon.href = faviconUrl;
            }

            storage.setItem('faviconUrl', favIcon);
        }
    };

    static caseInsensetiveMatch = (str1: string, str2: string) => {
        return str1?.toLowerCase() === str2?.toLowerCase();
    };

    static isFirstMileConsignment = (status: string) => {
        const firstMileStatusList = ['softdata_upload', 'pickup_scheduled', 'pickup_awaited', 'not_picked_up'];
        return firstMileStatusList.includes(status);
    };

    static asciiValidator = (rule: any, value: any) => {
        if (value && !/^[\x20-\x7E]*$/.test(value)) {
            return Promise.reject(new Error('Invalid characters.'));
        }
        return Promise.resolve();
    };

    static lengthAndAsciiValidator = (minLength: number, maxLength: number) => {
        return (rule: any, value: any) => {
            return Helper.asciiValidator(rule, value).then(() => {
                if (value && value.length < minLength) {
                    return Promise.reject(new Error('Insufficient Characters'));
                }
                if (value && value.length > maxLength) {
                    return Promise.reject(new Error('Character Limit Reached'));
                }
                return Promise.resolve();
            });
        };
    };

    static asciiValidatorSync = (value: any, minValue: number, maxValue: number) => {
        if (value && !/^[\x20-\x7E]*$/.test(value)) {
            return false;
        }
        if (value && value.length < minValue) {
            return false;
        }
        if (value && value.length > maxValue) {
            return false;
        }
        return true;
    };

    static hexToRgb = (hex: string) => {
        const bigint = parseInt(hex.slice(1), 16);
        const r = Math.floor(bigint / (2 ** 16)) % 256;
        const g = Math.floor(bigint / (2 ** 8)) % 256;
        const b = bigint % 256;
        return [r, g, b];
    };

    static rgbToHSL = (paramR: number, paramG: number, paramB: number) => {
        const r = paramR / 255;
        const g = paramG / 255;
        const b = paramB / 255;

        const max = Math.max(r, g, b); const
            min = Math.min(r, g, b);
        let h;
        let s;
        const l = (max + min) / 2;

        if (max === min) {
            h = 0;
            s = 0;
        } else {
            const d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            // eslint-disable-next-line default-case
            switch (max) {
                case r: h = (g - b) / d + (g < b ? 6 : 0); break;
                case g: h = (b - r) / d + 2; break;
                case b: h = (r - g) / d + 4; break;
            }
            h /= 6;
        }

        return [Math.round(h * 360), Math.round(s * 100), Math.round(l * 100)];
    };

    static hslStringToArray = (hsl: string) => {
        const match = hsl.match(/\d+/g);
        if (!match) {
            throw new Error('Invalid HSL string');
        }
        const [h, s, l] = match.map(Number);
        return [h, s, l];
    };

    static colorToHSLArray = (color: string) => {
        if (color.startsWith('#')) {
            const [r, g, b] = this.hexToRgb(color);
            return this.rgbToHSL(r, g, b);
        } if (color.startsWith('rgb')) {
            // RGB format
            const rgbMatch = color.match(/\d+/g);
            if (!rgbMatch) {
                throw new Error('Invalid RGB string');
            }
            const [r, g, b] = rgbMatch.map(Number);
            return this.rgbToHSL(r, g, b);
        } if (color.startsWith('hsl')) {
            // Already in HSL format
            return this.hslStringToArray(color);
        }
        throw new Error('Unsupported color format');
    };

    static hslArrayToString(hslArray: [number, number, number]): string {
        const [h, s, l] = hslArray;
        return `hsl(${h}, ${s}%, ${l}%)`;
    }

    static getThemeColorFromPrimary = (primaryColor: string): DerivedTheme => {
        try {
            const [h, s, l] = this.colorToHSLArray(primaryColor);

            const clamp = (value: number, min: number, max: number) => Math.min(Math.max(value, min), max);

            const secondaryFill: [number, number, number] = [
                h,
                clamp(s - 20, 0, 100),
                clamp(l + 10, 0, 100),
            ];

            const tertiaryFill: [number, number, number] = [
                h,
                clamp(s - 15, 0, 100),
                clamp(l + 5, 0, 100),
            ];

            const stroke: [number, number, number] = [
                h,
                clamp(s - 30, 0, 100),
                clamp(l + 15, 0, 100),
            ];

            return {
                secondaryFillColor: this.hslArrayToString(secondaryFill),
                tertiaryFillColor: this.hslArrayToString(tertiaryFill),
                strokeColor: this.hslArrayToString(stroke),
            };
        } catch (e) {
            return {
                // TODO: Add default color as white and black
                secondaryFillColor: '#000000',
                tertiaryFillColor: '#000000',
                strokeColor: '#000000',
            };
        }
    };

    static elementOuterHeight = (el: HTMLElement) => {
        let height = el.offsetHeight;
        const style = getComputedStyle(el);

        height += parseInt(style.marginTop || '0', 10) + parseInt(style.marginBottom || '0', 10);
        return height;
    };

    static getCurrentRoute = () => {
        const path = window.location.pathname;
        const route = path.slice(1);
        // handle special case details route
        if (route.includes(Routes.CONSIGNMENT_DETAILS)) {
            return Routes.CONSIGNMENT_DETAILS;
        }
        if (route.includes(Routes.SELLER_DETAILS)) {
            return Routes.SELLER_DETAILS;
        }
        if (route.includes(Routes.ANALYTICS_DASHBOARD)) {
            return Routes.ANALYTICS;
        }
        return route;
    };
}

export default Helper;
